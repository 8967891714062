
/**
 * Calculate Age from Date
 * @param {*} startDateString 
 * @returns 
 */
export function calculateAge(startDateString) {
    if (startDateString === "")
      return 0;
    var today = new Date();
    var startDate = new Date(startDateString);
    var age = today.getFullYear() - startDate.getFullYear();
    var monthDiff = today.getMonth() - startDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < startDate.getDate())) {
      age--;
    }
    return age;
}
import React, { useState, useEffect } from "react";
import resumeFile from "../documents/resume.pdf";

const Resume = () => {
  // services details
  const [data, setData] = useState(null);
    
  useEffect(() => {
    fetch('db/resume.json')
      .then(response => response.json())
      .then(parsedData => {
        setData(parsedData); 
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);
  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Resume</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          A summary of My Resume
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          {/* My Education */}
          <div className="col-lg-6 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Education</h2>
            <div className="border-start border-2 border-primary ps-3">
              {data !== null && data.educationDetails.length > 0 &&
                data.educationDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
          { /* My Experience */ } 
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
          <h2 className="text-7 fw-600 mb-4 pb-2">My Experience</h2>
          <div className="border-start border-2 border-primary ps-3">
            {data !== null && data.experienceDetails.length > 0 &&
              data.experienceDetails.map((value, index) => (
                <div key={index}>
                  <h3 className="text-5">{value.title}</h3>
                  <p className="mb-2">
                    {value.place} / {value.yearRange}
                  </p>
                  <p className="text-muted">{value.desc}</p>
                  <hr className="my-4" />
                </div>
              ))}
          </div>
        </div>
        </div>
          
        {/* My Skills */}
        {/* <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">My Skills</h2>
        <div className="row gx-5">
          {data !== null && data.skills.length > 0 &&
            data.skills.map((skill, index) => (
              <div className="col-md-6 wow fadeInUp" key={index}>
                <p className="fw-500 text-start mb-2">
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div className="progress progress-sm mb-4">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div> */}
        <p className="text-center mt-5 wow fadeInUp">
          <a
            className="btn btn-outline-dark shadow-none rounded-0"
            href={resumeFile}
            download
          >
            Download CV
          </a>
        </p>
      </div>
    </section>
  );
};

export default Resume;

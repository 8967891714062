import React, { useState, useEffect } from "react";

const Services = () => {
  // services details
  const [data, setData] = useState(null);
  
  useEffect(() => {
    fetch('db/services.json')
      .then(response => response.json())
      .then(parsedData => {
        setData(parsedData); 
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <section id="services" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">What I Do?</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          How I can help your next project
        </h2>
        {/* Heading end*/}
        <div className="row gy-5 mt-5">
          {
          data !== null && data.services.length > 0 &&
            data.services.map((service, index) => (
              <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon text-primary text-13">
                    {" "}
                    <i className={service.icon} />
                  </div>
                  <h3 className="text-6 fw-600 mb-3">{service.name}</h3>
                  <p className="text-muted mb-0">{service.desc} </p>
                </div>
              </div>
            ))
            }
        </div>
      </div>
    </section>
  );
};

export default Services;
